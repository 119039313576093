import React from "react";
import "../index.css";
import { motion, useInView } from "framer-motion";

//assets
import MoneyBack from "../assets/moneyBack.svg";
import Send from "../assets/send.svg";
import ArcexOne from "../assets/arcexOne.jpg";
import ArcexTwo from "../assets/arcexTwo.png";

export default function Features() {

  const ref = React.useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });

  const sendRef = React.useRef(null);
  const isSendInView = useInView(sendRef, { amount: 0.5 });

  const liquidationRef = React.useRef(null);
  const isLiquidationInView = useInView(liquidationRef, { amount: "some" });

  const slideRef = React.useRef(null);
  const isSlideInView = useInView(slideRef, { amount: "some" });

  const investRef = React.useRef(null);
  const isInvestInView = useInView(investRef, { amount: "some" });

  const arcexTwoRef = React.useRef(null);
  const isArcexTwoInView = useInView(arcexTwoRef, { amount: "some" });

  const arcexOneRef = React.useRef(null);
  const isArcexOneInView = useInView(arcexOneRef, { amount: "some" });

  return (
    <div className="flex flex-col items-center justify-center min-h-[100vh] px-4 py-10 sm:px-0 w-full sm:w-[90%] mx-auto overflow-hidden">
      <h1 className="font-bold text-4xl sm:text-5xl text-center mb-10 px-8">
        <span className='whitespace-nowrap mr-2'>No experience?</span>
        <span className='whitespace-nowrap'>No problem!</span>
      </h1>

      <div className="flex grow w-full sm:w-2/3 lg:w-1/2 max-h-[280px] max-w-[700px] justify-between items-center bg-primary-200 border-2 border-primary-100 rounded-lg sm:rounded-xl shadow-xl">
        <motion.div
          ref={ref}
          className="flex items-center justify-center flex-shrink-0 z-10 relative bg-white border-2 border-primary-100 rounded-lg sm:rounded-xl shadow-xl h-[80px] sm:h-[100px] lg:h-[120px] w-1/3 sm:w-[120px] md:w-[160px] lg:w-[180px] max-w-[140px] sm:max-w-[180px] left-[-10px] sm:left-[-30px] lg:left-[-60px] top-[60px] lg:top-[30px]"
          whileHover={{ scale: 1.05, rotate: -5 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <img className="size-[50px] sm:size-[60px] md:size-[80px] lg:size-[100px]" src={MoneyBack} alt="MoneyBack" />
        </motion.div>
        <motion.div 
          className="flex flex-col justify-center items-center bg-white m-4 ml-0 rounded-lg sm:rounded-xl text-center shadow-xl max-w-[340px] min-h-[160px] py-3 lg:py-6 px-3"
          ref={liquidationRef}
          animate={{ opacity: isLiquidationInView ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="font-bold text-[1.1rem] sm:text-[1.6rem]">
            Redefining Liq&bull;ui&bull;da&bull;tion
          </h1>
          <h2 className='mt-4'>
            Earn a % of your liquidation back by providing volume on <b>Arcex</b>
          </h2>
        </motion.div>
      </div>

      <div className="flex w-full sm:w-2/3 lg:w-1/2 max-w-[700px] mt-2 sm:mt-4 gap-2 sm:gap-4">
        <motion.div
          ref={slideRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ 
            x: isSlideInView ? 0 : -100, 
            opacity: isSlideInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
          className="flex flex-col justify-center relative border-2 border-primary-100 rounded-lg sm:rounded-xl w-2/3 p-4 shadow-xl"
        >
          <motion.div
            ref={sendRef}
            className="absolute flex items-center justify-center z-20 bg-primary-300 border-2 border-primary-100 origin-center top-[-34px] lg:top-[-48px] right-[-49px] sm:right-[-53px] lg:right-[-68px] rounded-lg sm:rounded-xl shadow-xl h-[60px] lg:h-[80px] w-[90px] lg:w-[120px]"
            whileHover={{ scale: 1.05, rotate: 5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: isSendInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <img className="size-[30px] sm:size-[40px] lg:size-[60px]" src={Send} alt="Send" />
          </motion.div>
          <h1 className="font-bold text-[1.2rem] sm:text-[1.6rem]">Send to anyone</h1>
          <h2 className="mt-2">Securely send your assets to others in seconds with ease</h2>
        </motion.div>
        <motion.div 
          ref={arcexTwoRef}
          style={{ backgroundImage: `url(${ArcexTwo})` }}
          className="flex items-center border-2 border-primary-100 z-10 shadow-xl rounded-lg sm:rounded-xl bg-[#e5f7ed] w-1/3 bg-cover bg-no-repeat bg-center overflow-hidden"
          initial={{ x: 100, opacity: 0 }}
          animate={{ 
            x: isArcexTwoInView ? 0 : 100, 
            opacity: isArcexTwoInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
          whileHover={{ y: -12 }}
        >
        </motion.div>
      </div>

      <div className="flex relative grow max-h-[160px] w-full sm:w-2/3 lg:w-1/2 max-w-[700px] mt-2 sm:mt-4 gap-2 sm:gap-4">
        <motion.div
          ref={investRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ 
            x: isInvestInView ? 0 : -100, 
            opacity: isInvestInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.5, ease: "easeOut" }}
          className="flex flex-col justify-center border-2 border-primary-100 shadow-xl rounded-lg sm:rounded-xl w-2/3 p-4"
        >
          <h1 className="font-bold text-[1.2rem] sm:text-[1.6rem]">Invest simply</h1>
          <h2 className="mt-2">Access many instruments across various markets</h2>
        </motion.div>
        <motion.div 
          ref={arcexOneRef}
          className="flex flex-col border-2 border-primary-100 shadow-xl rounded-lg sm:rounded-xl w-1/2 bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: `url(${ArcexOne})` }}
          initial={{ x: 100, opacity: 0 }}
          animate={{ 
            x: isArcexOneInView ? 0 : 100, 
            opacity: isArcexOneInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.6, ease: "easeOut" }}
          whileHover={{ y: -12 }}
        >
        </motion.div>
      </div>
    </div>
  );
}
