import React, {useState} from "react";

//assets
import LogoNew from '../assets/logoDarkGreen.svg';
import Bell from '../assets/bell.svg';

const handleScrollToBottom = (event) => {
  event.preventDefault()
  window.scrollTo({
      top: 9000,
      behavior: 'smooth',
  });
};

export default function Navbar() {
  const [updatesModal, setUpdatesModal] = useState(false);
  const [formEmail, setFormEmail] = useState({email: ""});

  const handleUpdatesModal = () => {
    setUpdatesModal(!updatesModal)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormEmail({ ...formEmail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", formEmail)

    try {
      const response = await fetch("https://landing-be-production.up.railway.app/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formEmail),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Signed up successfully");
    } catch (error) {
      console.error("Error signing up for updates:", error);
    }
    setUpdatesModal(false)
  };

  const modalComponent = () => (
    <div className="fixed inset-0 flex items-center justify-center cursor-pointer bg-black bg-opacity-60 z-50" onClick={handleUpdatesModal}>
      <div className="flex flex-col items-center grow gap-2 border-2 border-primary-100 max-w-[500px] cursor-default w-full sm:w-auto bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8" onClick={(e) => e.stopPropagation()}>
        <h1>Get Updates</h1>
        <h2 className='text-center'>Enter your email to stay updated on Arcex</h2>
        <form className="flex flex-col gap-4 grow w-full max-w-[400px]" onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            className=" w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
            placeholder="your@email.com"
            onChange={handleChange}
            required
            autoComplete="on"
          />

          <button type="submit" className="inline-flex justify-center py-2 border-2 border-primary-100 shadow-md rounded-md text-primary-100 bg-primary-200 hover:bg-white transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-100">
            <h2>Get Updates</h2>
          </button>
        </form>
      </div>
    </div>
  )


  return (
    <div className="flex justify-between items-center py-4 px-4 sm:px-0 w-full sm:w-[90%] mx-auto relative">
        <div className="flex items-center sm:w-[145px] md:w-[160px]">
            {/* 
            <img src={Logo} alt='Arcex' className='size-[40px] md:size-[50px] sm:mr-2' />
            <h1 className='hidden sm:inline-block'>Arcex</h1>
            */}
            <img src={LogoNew} alt='Arcex' className='w-[80px] md:w-[120px] h-[auto]' />
        </div>
        <div className="flex items-center justify-around sm:justify-between grow max-w-[210px] md:max-w-[260px]">
            <h3 className="cursor-pointer">Investors</h3>
            <h3 className="cursor-pointer">Features</h3>
            <h3 className="cursor-pointer" onClick={handleScrollToBottom}>Contact</h3>
        </div>
        <div onClick={handleUpdatesModal} className="flex items-center justify-center gap-2 bg-primary-200 border-2 border-primary-100 cursor-pointer px-2 sm:px-0 sm:w-[145px] md:w-[160px] py-2 rounded-lg sm:rounded-xl">
          <img src={Bell} alt='Notify me' className='animate-bell size-[20px]' />
          <h3 className='hidden sm:inline-block'>Get Updates</h3>
        </div>

        {updatesModal && modalComponent()}
    </div>
  );
}